import { Injectable, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, interval } from 'rxjs';

import { environment } from 'src/environments/environment';

import { map } from 'rxjs/operators';

import { ObterIdentificadorService, ObterIdentificador } from '../sign-in/obter-identificador.service';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';


export interface SignIn {
  fantasia?: string,
  codTabelaPreco?: string,
  usuario?: string,
  id?: number,
  cnpj?: string,
  email?: string,
  codCliente?: string,
  perfil?: {
    id: number,
    perfil: string
  },
  token: string
}

export interface Login {
  email: string,
  senha: string
}

@Injectable({
  providedIn: 'root'
})
export class SessionService implements OnInit {

  private relativeLink = 'public/v1/drctLogin';

  private isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(localStorage.getItem('information') != null ? true : false);
  private info$: BehaviorSubject<object> = new BehaviorSubject<object>({});
  private identificadorMarca$: BehaviorSubject<ObterIdentificador> = new BehaviorSubject<ObterIdentificador>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private obterIdentificadorService: ObterIdentificadorService
  ) { }

  ngOnInit(): void {
    this.isActive();
    this.identificadorUrl();
  }

  get information$(): Observable<SignIn> {
    const info = JSON.parse(localStorage.getItem('information'));
    this.info$.next(info);
    return this.info$ as Observable<SignIn>;
  }

  get isLogged$() {
    const information: SignIn = JSON.parse(localStorage.getItem('information'));
    if (!information) {
      this.isLoggedIn$.next(false);
    } else {
      this.isLoggedIn$.next(true);
    }
    return this.isLoggedIn$.asObservable();
  }

  get identificador$() {
    const identificador: ObterIdentificador = JSON.parse(localStorage.getItem('identificador'));
    this.identificadorMarca$.next(identificador);
    return this.identificadorMarca$.asObservable();
  }

  login(user: Login) {
    return this.http.post(`${environment.url.api}/${this.relativeLink}`, user)
      .pipe(
        map((item: SignIn) => {
          if (item) {
            localStorage.setItem('information', JSON.stringify(item));
            this.isLoggedIn$.next(true);
          }
        })
      )
  }

  /**S
   * FUNÇÃO QUE EFETUA O LOGOUT
   * ELA LIMPA A SESSION E NAVEGA PARA O LOGIN
   */
  logout() {
    localStorage.removeItem('information');
    this.isLoggedIn$.next(false);
    this.router.navigate(['login']);
  }

  isActive() {
    this.isLogged$.subscribe((data) => {
      if (data == true || this.router.url == '/user-registration' || this.router.url.indexOf('cadastro-liberado') != -1 || this.router.url == '/falha-ativacao' || this.router.url == '/forgot-password') {

      } else {
        this.router.navigate(['login']);
      }
    })
  }

  identificadorUrl() {
    this.obterIdentificadorService
      .obterIdentificador()
      .subscribe((data) => {
        localStorage.setItem('identificador', JSON.stringify(data))
      },
        (error: HttpErrorResponse) => {
          console.log('Error ao obter o identificador.', error.error['mensagem']);
        })
  }

}
