import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

export interface ObterIdentificador {
  whatsapp: string,
  cidade: string,
  telefone: string,
  urlLogoPositivo: string,
  numero: number,
  endereco: string,
  ddd: string,
  urlLogoDirect: string,
  bairro: string,
  nome: string,
  urlLogoNegativo: string,
  urlLogoGrupo: string,
  cep: string,
  uf: string,
  site: string,
  urlLogoOriginal: string,
  identificador: number
}

@Injectable({
  providedIn: 'root'
})
export class ObterIdentificadorService {

  constructor(
    private http: HttpClient,
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) { }

  public obterIdentificador(): Observable<ObterIdentificador> {
    let dominio: string = window.location.href.toString();
    let marca: string;
    let title: string;
    let favIcon: string;
    if (dominio.indexOf('teste') != -1 || dominio.indexOf('maioricasa') != -1 || dominio.indexOf('localhost') != -1) {
      marca = 'maioricasa';
      title = 'Maiori Casa';
      favIcon = '../../../assets/images/favicon/favicon-maiori.png';
    } else if (dominio.indexOf('asiatex') != -1) {
      marca = 'asiatex';
      title = 'Asiatex';
      favIcon = '../../../assets/images/favicon/favicon-asia.ico';
    }
    this._document.getElementById("appFavicon").setAttribute("href", favIcon);
    this.title.setTitle(title);
    return this.http.get(`${environment.url.api}/public/v1/drctIdentificadorCliente?dominio=${marca}`) as Observable<ObterIdentificador>;
  }

}
