import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { SessionService, SignIn } from 'src/app/services/session/session.service';
import { ObterIdentificador } from 'src/app/services/sign-in/obter-identificador.service';
import { map, switchMap } from 'rxjs/operators';


@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor(private session: SessionService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let identificador: string;

    this.session.identificador$.subscribe((data: ObterIdentificador) => {
      identificador = data?.identificador.toString()
    })

    let token: string;
    let logged: boolean;
    this.session.isLogged$.subscribe((data) => {
      logged = data;
    })
    if (logged) {
      this.session.information$.subscribe((data: SignIn) => {
        token = data.token
      })
    }

    if (request.url.search('drctIdentificadorCliente') != -1) {
      request = request.clone({
        headers: request.headers.set('identificador', '')
      })
    } else if (request.url.search('public') != -1 && identificador != null) {

      request = request.clone({
        headers: request.headers.set('identificador', identificador)
      })

    } else if (request.url.search('private') != -1 && token != null) {

      if (token == null) {
        this.session.logout();
      } else {

        request = request.clone({
          headers: request.headers.set('Authorization', `Token ${token}`)
        })
      }

    }
    //Identificador MaioriCasa: 67365910546 | Asiatex: 224262964110 | GoSales: 3654862123
    return next.handle(request);
  }

}