import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { interval } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

import { Category } from '../app.models';
import { Settings, AppSettings } from '../app.settings';
import { BuscaService, BuscaItem } from '../services/busca/busca.service';
import { CartService, ResumoCarrinho, CarrinhoRetorno, CarrinhoRetornoItens } from '../services/cart/cart.service';
import { SessionService, SignIn } from '../services/session/session.service';
import { ObterIdentificadorService, ObterIdentificador } from '../services/sign-in/obter-identificador.service';
import { SidenavMenuService } from '../theme/components/sidenav-menu/sidenav-menu.service';
import { MatInput } from '@angular/material/input';
import { UtilService } from '../services/util/util.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [SidenavMenuService]
})
export class PagesComponent implements OnInit {

  public showBackToTop: boolean = false;

  public categories: Category[];

  public category: Category;

  public sidenavMenuItems: Array<any>;

  @ViewChild('sidenav', { static: true }) sidenav: any;

  lista: Array<BuscaItem> = [];

  pesquisaForm: FormGroup;

  public settings: Settings;

  esconder: boolean;

  resumoCarrinho: ResumoCarrinho;

  codCliente: string;

  carrinho: CarrinhoRetorno;

  dadosEmpresa: ObterIdentificador;

  qtdeTotalItens: number;

  public codTabelaPreco: string = '';

  private itensOriginal: Array<CarrinhoRetornoItens> = [];

  public whatsapp: string;

  constructor(
    public appSettings: AppSettings,
    public sidenavMenuService: SidenavMenuService,
    public router: Router,
    private sessionService: SessionService,
    private buscaService: BuscaService,
    private fb: FormBuilder,
    private cartService: CartService,
    private snackBar: MatSnackBar,
    private obterIdentificadorService: ObterIdentificadorService,
    private utilService: UtilService
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.cartService.emitCart.pipe(debounceTime(1000))
      .subscribe((data) => {
        this.getResumoCarrinho();
      })

      this.cartService.emitPedido.subscribe((data) => {
        this.resumoCarrinho.total = 0;
        this.resumoCarrinho.quantidade = 0;
        this.resumoCarrinho.totalSemIpi = 0;
        this.carrinho.itens = [];
        this.qtdeTotalItens = 0;
      })


    this.pesquisaForm = this.fb.group({
      valorPesquisa: ['', []]
    })

    this.sessionService.identificadorUrl();

    this.sessionService.information$
      .subscribe((data: SignIn) => {
        if (data.codCliente == null || data.codCliente == '' || data.codTabelaPreco == null || data.codTabelaPreco == '') {
          this.codCliente = '';
          this.codTabelaPreco = '';
        } else {
          this.codCliente = data.codCliente
          this.codTabelaPreco = data.codTabelaPreco
        }
      })

    this.obterIdentificadorService
      .obterIdentificador()
      .subscribe((data: ObterIdentificador) => {
        this.dadosEmpresa = data
        this.whatsapp = this.utilService.mascaraDeTelefone(data.whatsapp.substr(2));
        const valorAtual = data.telefone;
        const isCelular = valorAtual.length === 9
        let telMask;
        if (isCelular) {
          const x = valorAtual.slice(0, 5);
          const y = valorAtual.slice(5, 9);
          telMask = `${x}-${y}`
        } else {
          const x = valorAtual.slice(0, 4);
          const y = valorAtual.slice(4, 8);
          telMask = `${x}-${y}`
        }

        data.telefone = telMask;
      })

    this.getResumoCarrinho();

    interval(2000).subscribe((_) => {
      this.sessionService.isActive();
    });
    this.esconder = true;


    this.pesquisaForm.controls['valorPesquisa']
      .valueChanges
      .pipe(debounceTime(250))
      .subscribe((data: string) => {
        if (data.length > 0) {
          this.search();
        } else {
          this.lista = [];
          this.esconder = true;
        }
      })
  }

  public changeCategory(event) {
    if (event.target) {
      this.category = this.categories.filter(category => category.name == event.target.innerText)[0];
    }
    if (window.innerWidth < 960) {
      this.stopClickPropagate(event);
    }
  }

  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  public search() {
    this.buscaService
      .buscaPorProdutos(this.pesquisaForm.controls['valorPesquisa'].value)
      .subscribe((data) => {
        console.log('dados search: ', data);
        this.lista = data.lista;
        console.log(this.lista)

        for (var i = 0; i < data.lista.length; i++) {
          let imagens = data.lista[i].imagens
          let itens = imagens.filter((img => img.tipoImagem === 1))
          if (itens.length > 0) {
            data.lista[i].urlGrade = itens[0].url
          } else {
            let itens = imagens.filter((img => img.tipoImagem === 2))
            if (itens.length > 0) {
              data.lista[i].urlGrade = itens[0].url
            } else {
              let itens = imagens.filter((img => img.tipoImagem === 3))
              if (itens.length > 0) {
                data.lista[i].urlGrade = itens[0].url
              }
            }
          }
        }


        // if (this.lista.length == 0 || this.lista.length == 1) {
        if (this.lista.length <= 0) {
          this.esconder = false;
          this.lista.push({ codigo: '', descricao: 'Sem Resultados', urlGrade: 'assets/images/others/error-24px.svg', imagens: [{ tipoImagem: 1, url: 'assets/images/others/search.svg' }], codigoGrade: '', ordem: 0, codigoCor: '', descricaoCor: '', descricaoGrade: 'Sem Resultados' });
        } else {
          this.esconder = false;
          this.lista.push({ codigo: 'resultadoPesquisa', descricao: 'Todos os Resultados', urlGrade: 'assets/images/others/search.svg', imagens: [{ tipoImagem: 1, url: 'assets/images/others/search.svg' }], codigoGrade: '', ordem: 0, codigoCor: '', descricaoCor: '', descricaoGrade: 'Todos os Resultados' });
        }
      })
  }

  public changeRoute(codigoGrade: string, codTabelaPreco?: string) {
    if (codigoGrade != 'resultadoPesquisa' && codigoGrade.length > 0) {
      this.router.navigate(['/products', codigoGrade, codTabelaPreco]);
    } else if (codigoGrade == 'resultadoPesquisa') {
      this.router.navigate(['/products/pesquisa', this.pesquisaForm.controls['valorPesquisa'].value, 0]);
    }
    this.zerarLista();
  }

  public enterPesquisa() {
    if (this.lista.length > 1) {
      this.router.navigate(['/products/pesquisa', this.pesquisaForm.controls['valorPesquisa'].value, 0]);
    } else {
      this.snackBar.open(`Sem resultado para a pesquisa: ${this.pesquisaForm.controls['valorPesquisa'].value}`, '', {
        panelClass: 'warn', verticalPosition: 'bottom', duration: 2000
      });
      this.router.navigate(['/']);
    }
    this.zerarLista();
  }

  public zerarLista() {
    this.lista = [];
    this.pesquisaForm.controls['valorPesquisa'].setValue('')
    this.esconder = true;
  }


  public scrollToTop() {
    var scrollDuration = 200;
    var scrollStep = -window.pageYOffset / (scrollDuration / 20);
    var scrollInterval = setInterval(() => {
      if (window.pageYOffset != 0) {
        window.scrollBy(0, scrollStep);
      }
      else {
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= 768) {
      setTimeout(() => { window.scrollTo(0, 0) });
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    ($event.target.documentElement.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;
  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();
      }
    });
    this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems());
  }

  public closeSubMenus() {
    if (window.innerWidth < 960) {
      this.sidenavMenuService.closeAllSubMenus();
    }
  }

  public getResumoCarrinho() {
    let log;
    this.sessionService.isLogged$.subscribe(data => {
      log = data;
    });

    if (log) {
      this.cartService
        .resumoCarrinho(this.codCliente)
        .subscribe((item) => {
          this.resumoCarrinho = item;
          // console.log(this.resumoCarrinho)
          this.getObterCarrinho();
        },
          (error: HttpErrorResponse) => {
            console.log('Error: ', error.error);
          })
    }
  }

  public getObterCarrinho() {
    this.cartService
      .obterCarrinho(this.codCliente)
      .subscribe((data) => {
        this.itensOriginal = [];
        // if (data.itens.length > 0 || data.itens && 'itens' in data) {
        if ('itens' in data) {
          let itens: Array<CarrinhoRetornoItens> = [];
          const itensSM0 = data.itens.filter(item => item.vendaSobMedidaM2 !== 1);
          const itensSM1 = data.itens.filter(item => item.vendaSobMedidaM2 === 1);
          const qtdItensSM0 = itensSM0.reduce(function (accumulator, pilot) {
            return accumulator + pilot.quantidade;
          }, 0);
          this.qtdeTotalItens = qtdItensSM0 + itensSM1.length
          data.itens.forEach((item) => {
            this.itensOriginal.push(item);
            if (item.vendaSobMedidaM2 == 1) { item.quantidade = 1; };
            let duplicated = itens.findIndex(redItem => {
              //  return item.codProduto == redItem.codProduto;
              if (item.codProduto == redItem.codProduto && item.vendaSobMedidaM2 != 1) {
                redItem.quantidade += item.quantidade
                return item
              }
            }) > -1;

            if (!duplicated) {
              itens.push(item);
            }
          })
          data.itens = itens;
          this.carrinho = data;
          // console.log(this.carrinho)
        } else {
          // this.carrinho.itens = [];
          this.qtdeTotalItens = 0;
        }
      })
  }

  public excluirItem(item: CarrinhoRetornoItens) {
    let fil: Array<CarrinhoRetornoItens> = this.itensOriginal.filter(x => x.codProduto == item.codProduto);

    for (let i = 0; i < fil.length; i++) {
      this.cartService
        .excluirItem(
          this.codCliente,
          fil[i].id,
          fil[i].idProduto,
          fil[i].codProduto)
        .subscribe((_) => {
          this.snackBar.open(`Sucesso ao  excluir item ${item.descricao}`, '', {
            panelClass: 'success', verticalPosition: 'bottom', duration: 2000
          });
        },
          (error: HttpErrorResponse) => {
            this.snackBar.open(`Erro ao excluir item ${item.descricao}. Error: ${error.error}`, '', {
              panelClass: 'warn', verticalPosition: 'bottom', duration: 2000
            });
          })
    }
    this.getResumoCarrinho();
  }

  public excluirCarrinho() {
    this.carrinho.itens.map((item) => {
      this.excluirItem(item);
    })
  }

  public formatterValue(valor) {
    let val = (valor / 1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }

  public setFocusInput() {
    document.getElementById('inputPesquisa').focus();
  }

}