import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AgmCoreModule } from '@agm/core';

import { OverlayContainer, Overlay } from '@angular/cdk/overlay';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxSpinnerModule } from 'ngx-spinner';

import { ClickOutsideModule } from 'ng-click-outside';

import { NgxMaskModule, IConfig } from 'ngx-mask';

import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { AppService } from './app.service';
import { AppSettings } from './app.settings';

import { PagesComponent } from './pages/pages.component';
import { SharedModule } from './shared/shared.module';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { NumberDirective } from './theme/directives/numbers-only.directive';
import { AppInterceptor } from './theme/utils/app-interceptor';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import { menuScrollStrategy } from './theme/utils/scroll-strategy';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { MenuComponent } from './theme/components/menu/menu.component';
import { OptionsComponent } from './theme/components/options/options.component';
import { SidenavMenuComponent } from './theme/components/sidenav-menu/sidenav-menu.component';
import { TopMenuComponent } from './theme/components/top-menu/top-menu.component';
import { AppInterceptorToken } from './app.interceptor'

export let options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAir4tXhx3X-wcdZnhe8TLlo9J2m_AKx6w'
    }),
    SharedModule,
    routing,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    NgxMaskModule.forRoot(options)
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    NotFoundComponent,
    TopMenuComponent,
    MenuComponent,
    SidenavMenuComponent,
    BreadcrumbComponent,
    OptionsComponent,
    FooterComponent,
    NumberDirective
  ],
  providers: [
    AppSettings,
    AppService,
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: MAT_MENU_SCROLL_STRATEGY, useFactory: menuScrollStrategy, deps: [Overlay] },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS, useClass: AppInterceptorToken, multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }