import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from './app.settings';
import { SessionService } from './services/session/session.service';
import { interval } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  loading: boolean = false;
  public settings: Settings;

  constructor(
    public appSettings: AppSettings,
    public router: Router,
    private sessionService: SessionService
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    interval(2000).subscribe((_) => this.sessionService.isActive());
  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    })
  }
}
