import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface HomeSessoes {
  menus: Array<ItemMenu>,
  sessoes: [
    {
      sessao: string,
      produtos: Array<any>,
      ordem: number,
      id: number
    }
  ]
}

export interface ItemMenu {
  codigo: string,
  descricaoMenu: string,
  subTitulo: string,
  ordem: number,
  publicado: number,
  descricao: string,
  subMenu: Array<SubMenu>
  exibeAgrupamentoCores?: number
}

export interface SubMenu {
  codigo: string,
  descricao: string,
  breadcrumb?: string,
  exibeAgrupamentoCores?: number,
  ordem: number
}

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }

  homeSessoes(codTabelaPreco?: string): Observable<HomeSessoes> {
    return this.http.get(`${environment.url.api}/private/v1/drctHomeSessoes?codTabelaPreco=${codTabelaPreco}`) as Observable<HomeSessoes>;
  }

  homeBanners() {
    return this.http.get(`${environment.url.api}/private/v1/drctHomeBanners`);
  }

}
