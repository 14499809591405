import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CarrinhoRetornoItens, CartService, CarrinhoEnvioItem, CarrinhoEnvio } from 'src/app/services/cart/cart.service';
import { SessionService } from 'src/app/services/session/session.service';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { pipe, Subject } from 'rxjs';

import { Product } from '../../app.models';
import { Data, AppService } from '../../app.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {

  @Input() product: CarrinhoRetornoItens;

  @Input() type: string;

  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();

  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() add: boolean;

  public count: number = 1;

  public align = 'center center';

  private codCliente: string;

  public txtQueryChanged: Subject<number> = new Subject<number>();

  constructor(
    public appService: AppService,
    public snackBar: MatSnackBar,
    private cartService: CartService,
    private sessionService: SessionService
  ) {
    this.txtQueryChanged.pipe(
      debounceTime(500), // wait 1 sec after the last event before emitting last event
      distinctUntilChanged()) // only emit if value is different from previous value
      .subscribe(model => {
        this.changeModel(model)

        // Call your function which calls API or do anything you would like do after a lag of 1 sec
        // this.getDataFromAPI(this.txtQuery);
      });
  }

  ngOnInit() {
    this.sessionService.information$.subscribe(data => this.codCliente = data.codCliente);
    if (this.product) {
      if (this.product.quantidade > 0) {
        this.count = this.product.quantidade;
      }
    }
    this.layoutAlign();
  }

  public layoutAlign() {
    if (this.type == 'all') {
      this.align = 'space-between center';
    }
    else if (this.type == 'wish') {
      this.align = 'start center';
    }
    else {
      //this.align = 'center center'; -- Utilizado quando possui mais botões
      this.align = 'start center';
    }
  }

  public increment() {
    if (this.count < (this.product.estoque + this.product.qtdLimitePedidoAcimaEstoque)) {
      this.count = this.count + 1;
      this.product.quantidade = this.count;
      if (this.add) {
        this.changeQuantity(this.product);
      }
    }
    else {
      this.count = this.product.estoque + this.product.qtdLimitePedidoAcimaEstoque;
      this.snackBar.open('Você não pode escolher mais itens do que o disponível. Em Estoque ' + this.count + ' itens.', '×', { panelClass: 'warning', verticalPosition: 'bottom', duration: 3000 });
    }
  }

  public decrement() {
    if (this.count > 1) {
      this.count = this.count - 1;
      this.product.quantidade = this.count;
      this.add = this.add == undefined ? false : true;
      if (this.add) {
        this.changeQuantity(this.product);
      }
    }
  }

  public addToCompare(product: Product) {
    this.appService.addToCompare(product);
  }

  public addToWishList(product: Product) {
    this.appService.addToWishList(product);
  }

  public addToCart(product: CarrinhoRetornoItens) {
    // let currentProduct: Array<CarrinhoRetornoItens> = [];
    let itensOriginais: Array<CarrinhoRetornoItens> = [];
    let idSessao: string;
    this.cartService.obterCarrinho(this.codCliente).subscribe((data) => {
      itensOriginais = data.itens.filter(item => item.codProduto == product.codProduto);
      idSessao = data.idSessao;

      if (itensOriginais.length > 1) {
        for (let i = 1; i < itensOriginais.length; i++) {
          const element = itensOriginais[i];

          this.cartService.excluirItem(
            this.codCliente,
            element.id,
            element.idProduto,
            element.codProduto
          ).subscribe((data) => {
            console.log(`Item excluído: ${data.mensagem}`);
          },
            (error: HttpErrorResponse) => {
              console.log('Erro ao atualizar carrinho');
            })
        }
      }
      console.log(itensOriginais);

    });

    if (this.product.quantidade > (this.product.estoque + this.product.qtdLimitePedidoAcimaEstoque)) {
      this.snackBar.open('Você não pode escolher mais itens do que o disponível. Em Estoque ' + (this.product.estoque + this.product.qtdLimitePedidoAcimaEstoque) + ' itens e você já adicionou ' + product.quantidade + ' item ao seu carrinho', '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 5000 });
      return false;
    }
    let usuario: string;
    this.sessionService.information$.subscribe((data) => {
      // idSessao = data.token;
      usuario = data.email;
    });
    let item: CarrinhoEnvioItem = {
      codProduto: product.codProduto,
      comprimento: product.comprimento,
      id: product.id,
      idProduto: product.idProduto.toString(),
      largura: product.largura,
      lotes: product.lotes,
      precoUnit: product.preco,
      quantidade: product.quantidade.toString(),
      redondo: 0,
      tipoBorda: 0
    }
    let obj: CarrinhoEnvio = {
      codCliente: this.codCliente,
      idSessao: idSessao,
      usuario: usuario,
      itens: [item]
    }
    this.cartService.insertCarrinho(obj).pipe(debounceTime(250)).subscribe((data) => {
      this.onQuantityChange.emit(data.itens);
    });
  }

  public openProductDialog(event) {
    this.onOpenProductDialog.emit(event);
  }

  public changeQuantity(value) {
    // this.onQuantityChange.emit(value);
    this.addToCart(value);
  }

  public changeModel(event: number) {
    // let quantidade: number = event != null ? parseInt(event['target']['value'], 10) : 1;
    let quantidade: number = event;
    if (quantidade <= 0) {
      quantidade = 1;
      this.count = quantidade;
      this.product.quantidade = this.count;
      this.changeQuantity(this.product);
    } else if (quantidade <= (this.product.estoque + this.product.qtdLimitePedidoAcimaEstoque)) {
      this.count = quantidade;
      this.product.quantidade = this.count;
      this.changeQuantity(this.product);
    } else if (quantidade > (this.product.estoque + this.product.qtdLimitePedidoAcimaEstoque)) {
      quantidade = this.product.estoque + this.product.qtdLimitePedidoAcimaEstoque;
      this.count = quantidade;
      this.product.quantidade = this.count;
      this.changeQuantity(this.product);
      this.snackBar.open(`Quantidade Máxima em estoque ${this.count}.`, '', { panelClass: 'warning', verticalPosition: 'bottom', duration: 3000 });
    }
    // console.log('quantidade: ', quantidade, '\nproduct.estoque: ', this.product.estoque, '\nproduct.qtdLimitePedidoAcimaEstoque: ', this.product.qtdLimitePedidoAcimaEstoque, '\nproduct.qtdLimitePedidoAcimaEstoque + product.estoque: ', this.product.qtdLimitePedidoAcimaEstoque + this.product.estoque);

  }

  changeModelDebounce(event: number) {
    if (event != null && typeof (event) == 'object') {
      parseInt(event['target']['value'], 10)
      this.txtQueryChanged.next(event);
    } else if (event != null) {
      this.txtQueryChanged.next(event);
    } else {
      return;
    }
  }

}