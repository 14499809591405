import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ObterIdentificadorService, ObterIdentificador } from 'src/app/services/sign-in/obter-identificador.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  dadosEmpresa: ObterIdentificador
  imagem: any

  constructor(
    public router: Router,
    public obterIdentificadorService: ObterIdentificadorService) { }

  ngOnInit() {

    this.obterIdentificadorService
      .obterIdentificador()
      .subscribe((data: ObterIdentificador) => {
        this.dadosEmpresa = data
        this.imagem = data.urlLogoPositivo
      })
  }

  public goHome(): void {
    this.router.navigate(['/']);
  }

}
