import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

export interface Busca {
  lista: Array<BuscaItem>
}

export interface BuscaItem {
  urlGrade: string,
  codigo: string,
  imagens: [
    {
      tipoImagem: number,
      url: string
  }
  ],
  codigoGrade: string,
  ordem: number,
  codigoCor: string,
  descricao: string,
  descricaoCor: string,
  descricaoGrade: string
}
//codigo: string,
//urlImagemGrade: string,
//descricao: string,

@Injectable({
  providedIn: 'root'
})
export class BuscaService {

  constructor(private http: HttpClient) { }

  buscaPorProdutos(termo?: string): Observable<Busca> {
    return this.http.get(`${environment.url.api}/private/v2/drctBuscaProdutos?termo=${termo}`) as Observable<Busca>;
  }
}
