import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from 'protractor';
import { ObterIdentificadorService } from 'src/app/services/sign-in/obter-identificador.service';
import { SessionService } from 'src/app/services/session/session.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  @Input() loading: boolean = false;

  public identificador;

  constCss = {
    visibility: 'hidden',
    width: '1000px',
    height: '1000px',
    backgroundColor: 'rgba(255, 254, 254, 0.8)',
    position: 'fixed',
    top: '0px',
    left: '0px',
    display: 'block'
  }

  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
    this.sessionService
      .identificador$
      .subscribe((data) => {
        this.identificador = data.identificador;
      },
        (error: HttpErrorResponse) => {
          console.log(error)
        })
  }

}
