import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

export const routes: Routes = [
    {
        path: '',
        component: PagesComponent, children: [
            { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
            { path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule), data: { breadcrumb: 'Minha Conta' } },
            { path: 'compare', loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule), data: { breadcrumb: 'Comparar' } },
            { path: 'wishlist', loadChildren: () => import('./pages/wishlist/wishlist.module').then(m => m.WishlistModule), data: { breadcrumb: 'Lista de Desejos' } },
            { path: 'cart', loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule), data: { breadcrumb: 'Carrinho' } },
            { path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule), data: { breadcrumb: 'Checkout' } },
            { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'Contato' } },
            { path: 'brands', loadChildren: () => import('./pages/brands/brands.module').then(m => m.BrandsModule), data: { breadcrumb: 'Marcas' } },
            { path: 'products', loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule), data: { breadcrumb: 'Todos os Produtos' } },
        ]
    },
    { path: 'login', loadChildren: () => import('./pages/sign-in/sign-in/sign-in.module').then(m => m.SignInModule), data: { breadcrumb: 'Login ' } },
    { path: 'forgot-password', loadChildren: () => import('./pages/sign-in/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule), data: { breadcrumb: 'Esqueci a minha Senha' } },
    { path: 'user-registration', loadChildren: () => import('./pages/sign-in/user-registration/user-registration.module').then(m => m.UserRegistrationModule), data: { breadcrumb: 'Cadastro ' } },
    { path: 'cadastro-liberado', loadChildren: () => import('./pages/sign-in/user-registration/cadastro-liberado/cadastro-liberado.module').then(m => m.CadastroLiberadoModule), data: { breadcrumb: 'Cadastro Liberado' } },
    { path: 'falha-ativacao', loadChildren: () => import('./pages/sign-in/user-registration/falha-ativacao/falha-ativacao.module').then(m => m.FalhaAtivacaoModule), data: { breadcrumb: 'Cadastro Liberado' } },
    { path: '**', component: NotFoundComponent },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
    // useHash: true
});