import { Injectable } from '@angular/core';

export class Settings {
    constructor(public name: string,
        public theme: string,
        public rtl: boolean) { }
}

@Injectable()
export class AppSettings {
    public settings = new Settings(
        window.location.href.toString().indexOf('maioricasa') != -1 || window.location.href.toString().indexOf('localhost') != -1 || window.location.href.toString().indexOf('teste') != -1 ? 'Maiori Casa' : 'Asiatex',  // theme name
        // 'Emporium',  // theme name
        'maioricasa',     // green, blue, red, pink, purple, grey
        false       // true = rtl, false = ltr
    )
}