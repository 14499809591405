import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class AppInterceptorToken implements HttpInterceptor {
    constructor(private router: Router,private snackBar: MatSnackBar) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(            
            catchError((error: HttpErrorResponse) => {
                console.log(error.status)
                if (error.status === 500) {
                    if (error.error.mensagem) {
                        this.snackBar.open(`${Object.values(error.error)[0]}`, '', {
                            panelClass: 'error', verticalPosition: 'bottom', duration: 5000
                        });
                    }
                }
                if (error.status === 401) {                    
                    localStorage.removeItem('information');
                    this.snackBar.open('Token de acesso inválido', '', {
                        panelClass: 'error', verticalPosition: 'bottom', duration: 2000
                      });
                    this.router.navigate(['login']);
                }
                return throwError(error.message);
               
                
            }));
    }
}

